<template>
  <div class="constrain part mt-8">
    <div class="row-12">
      <div class="md:col-8 md:offset-2">
        <h4 class="mb-8" v-html="payload.titel" />
        <div class="row-12 gutter-8">
          <div v-for="provider in providers" :key="provider.id" class="lg:col-6 flex">
            <div class="provider-box flex flex-col bg-white p-12">
              <h4 class="mb-0" v-html="provider.ort" />
              <p class="text-navy mb-4" v-html="provider.kursanbieter" />
              <div v-if="provider.adresse" class="provider-address mb-4 text-navy" v-html="provider.adresse" />
              <a
                v-if="provider.telefon"
                class="text-navy provider-link"
                :href="'tel:' + provider.telefon"
              >Tel.:{{ provider.telefon }}</a>
              <a
                v-if="provider.email"
                class="text-navy provider-link"
                :href="'mailto:' + provider.email"
              >{{ provider.email }}</a>
              <a
                v-if="provider.webseite"
                class="text-navy provider-link"
                :href="provider.webseite"
              >{{ provider.webseite.slice(provider.webseite.indexOf('.') + 1) }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: Object,
  },
  computed: {
    providers() {
      return this.payload.anbieter;
    },
  },
  mounted() {
    // console.log(this.payload);
  },
};
</script>

<style lang="scss" scoped>

.provider-box {
  width: 100%;
}

.provider-address {
  &:deep(p) {
    line-height: 1.3;
  }
}

.provider-link {
  &:hover {
    text-decoration: underline;
  }
}
</style>
